/** End game screen **/
#state-done {
    background-image: url('../img/bravo.png');
    background-position: center;
    background-size: cover;
}
#state-done:not(.hidden) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#state-done #done-actions {
    display: flex;
    justify-content: space-around;
    margin-bottom: 5%;
}

#state-done #done-actions button {
    border: none;

    text-transform: uppercase;
    font-weight: bold;
    padding: 1% 0;
    width: 40%;

    font-size: 1.2em;

    background-color: var(--color-secondary);
    color: white;
    -webkit-box-shadow: .2em .2em 0 .05em var(--color-primary-light);
    box-shadow: .2em .2em 0 .05em var(--color-primary-light);
}
