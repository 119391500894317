/** Review results screen **/
/* Layout */
#state-results:not(.hidden) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#state-results > *:not(:last-child) {
    margin-bottom: 2%;
}

#state-results #results-title {
    height: 5%;
    text-align: center;
    color: white;
    font-size: 1em;
}

#state-results #results-content {
    display: flex;
    justify-content: center;
}
#state-results #results-content .results-actions-container {
    text-align: center;
    width: 27%;
}
#state-results #results-content .results-actions-container:first-child {
    margin-right: 5%;
}

#state-results #results-content .results-actions-container .results-action-head {
    width: 20%;
    margin-bottom: 2%;
}

#state-results #results-content .results-actions-container .results-actions-list {
    border: 0.4em solid white;
    border-radius: 0.4em;
}
#state-results #results-content .results-actions-container .results-actions-list img {
    width: 100%;
    margin: 0;
    display: block;
}
#state-results #results-content .results-actions-container .results-actions-list img:not(:last-child) {
    width: 100%;
    border-bottom: 0.4em solid white;
}

/* Specific color for nok answers */
#state-results #results-content #results-actions-not-allowed .results-actions-list {
    border-color: var(--color-secondary);
    position: relative;
    overflow: hidden;
}
#state-results #results-content #results-actions-not-allowed .results-actions-list:after {
    content: "";
    height: 0.4em;
    background-color: var(--color-secondary);
    width: 250%;
    position: absolute;
    left: 0;
    bottom: -0.4em;
    transform: rotate(-60deg);
    transform-origin: bottom left;
}
#state-results #results-content #results-actions-not-allowed .results-actions-list img:not(:last-child) {
    border-color: var(--color-secondary);
}

#state-results #results-actions {
    display: flex;
    justify-content: space-around;
}

#state-results #results-actions  button {
    border: none;

    text-transform: uppercase;
    font-weight: bold;
    padding: 1% 0;
    width: 25%;

    font-size: 1.2em;

    background-color: var(--color-secondary);
    color: white;
    -webkit-box-shadow: .2em .2em 0 .05em var(--color-primary-light);
    box-shadow: .2em .2em 0 .05em var(--color-primary-light);
}
