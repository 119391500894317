@font-face {
    font-family: 'Avenir Next';
src('../font/AvenirNextLTProRegular.otf');
    font-weight: normal;
}
@font-face {
    font-family: 'Avenir Next';
src('../font/AvenirNextLTProDemi.otf');
    font-weight: 500;
}
@font-face {
    font-family: 'Avenir Next';
src('../font/AvenirNextLTProBold.otf');
    font-weight: bold;
}
@font-face {
    font-family: 'Avenir Next';
src('../font/AvenirNextLTPro-Heavy.otf');
    font-weight: 900;
}
