@font-face {
  font-family: Avenir Next;
  font-weight: normal;
}

@font-face {
  font-family: Avenir Next;
  font-weight: 500;
}

@font-face {
  font-family: Avenir Next;
  font-weight: bold;
}

@font-face {
  font-family: Avenir Next;
  font-weight: 900;
}

#state-playing > :not(:last-child) {
  margin-bottom: 1%;
}

#state-playing #game-title {
  height: 5%;
}

#state-playing #game-content {
  height: 58%;
}

#state-playing #game-answers {
  height: 34%;
}

#state-playing #game-answers #answers-details-container {
  height: 68%;
  margin-bottom: 2.5%;
}

#state-playing #game-answers #answers-footer {
  height: 23%;
}

#state-playing #game-title {
  text-align: center;
  color: #fff;
  font-size: 1em;
}

#state-playing #game-content {
  text-align: center;
}

#state-playing #game-content img {
  width: 57%;
  border: .4em solid #fff;
  border-radius: .4em;
  margin-bottom: .5%;
}

#state-playing #game-content #game-actions button {
  color: var(--color-secondary);
  text-transform: uppercase;
  width: 25%;
  -webkit-box-shadow: .2em .2em 0 .05em var(--color-secondary);
  box-shadow: .2em .2em 0 .05em var(--color-secondary);
  background-color: #fff;
  border: none;
  padding: 1% 0;
  font-size: 1em;
  font-weight: bold;
}

#state-playing #game-content #game-actions button:not(:last-child) {
  margin-right: 2%;
}

#state-playing #game-content #game-actions button.selected {
  background-color: var(--color-secondary);
  color: #fff;
  -webkit-box-shadow: .2em .2em 0 .05em var(--color-primary-light);
  box-shadow: .2em .2em 0 .05em var(--color-primary-light);
}

#state-playing #game-answers #answer {
  text-align: center;
  text-transform: uppercase;
  color: var(--color-secondary);
  margin-bottom: .5%;
  font-size: 1.5em;
  font-weight: bold;
}

#state-playing #game-answers #answer-details {
  height: 70%;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  margin: 0 4%;
  padding: 1% 3%;
  font-size: .8em;
  display: flex;
}

#state-playing #game-answers #answer-details p {
  color: var(--color-secondary);
  margin: 0;
}

#state-playing #game-answers #answers-footer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#state-playing #game-answers #answers-footer #answer-references {
  width: 95%;
  color: #fff;
  font-size: .5em;
}

#state-playing #game-answers #answers-footer #answer-references p {
  margin: 0;
}

#state-playing #game-answers #answers-footer button {
  width: 5%;
  -webkit-appearance: none;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  line-height: normal;
}

#state-playing #game-answers #answers-footer button img {
  width: 100%;
}

#state-done {
  background-image: url("bravo.5b31f5c5.png");
  background-position: center;
  background-size: cover;
}

#state-done:not(.hidden) {
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

#state-done #done-actions {
  justify-content: space-around;
  margin-bottom: 5%;
  display: flex;
}

#state-done #done-actions button {
  text-transform: uppercase;
  width: 40%;
  background-color: var(--color-secondary);
  color: #fff;
  -webkit-box-shadow: .2em .2em 0 .05em var(--color-primary-light);
  box-shadow: .2em .2em 0 .05em var(--color-primary-light);
  border: none;
  padding: 1% 0;
  font-size: 1.2em;
  font-weight: bold;
}

#state-results:not(.hidden) {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

#state-results > :not(:last-child) {
  margin-bottom: 2%;
}

#state-results #results-title {
  height: 5%;
  text-align: center;
  color: #fff;
  font-size: 1em;
}

#state-results #results-content {
  justify-content: center;
  display: flex;
}

#state-results #results-content .results-actions-container {
  text-align: center;
  width: 27%;
}

#state-results #results-content .results-actions-container:first-child {
  margin-right: 5%;
}

#state-results #results-content .results-actions-container .results-action-head {
  width: 20%;
  margin-bottom: 2%;
}

#state-results #results-content .results-actions-container .results-actions-list {
  border: .4em solid #fff;
  border-radius: .4em;
}

#state-results #results-content .results-actions-container .results-actions-list img {
  width: 100%;
  margin: 0;
  display: block;
}

#state-results #results-content .results-actions-container .results-actions-list img:not(:last-child) {
  width: 100%;
  border-bottom: .4em solid #fff;
}

#state-results #results-content #results-actions-not-allowed .results-actions-list {
  border-color: var(--color-secondary);
  position: relative;
  overflow: hidden;
}

#state-results #results-content #results-actions-not-allowed .results-actions-list:after {
  content: "";
  height: .4em;
  background-color: var(--color-secondary);
  width: 250%;
  transform-origin: 0 100%;
  position: absolute;
  bottom: -.4em;
  left: 0;
  transform: rotate(-60deg);
}

#state-results #results-content #results-actions-not-allowed .results-actions-list img:not(:last-child) {
  border-color: var(--color-secondary);
}

#state-results #results-actions {
  justify-content: space-around;
  display: flex;
}

#state-results #results-actions button {
  text-transform: uppercase;
  width: 25%;
  background-color: var(--color-secondary);
  color: #fff;
  -webkit-box-shadow: .2em .2em 0 .05em var(--color-primary-light);
  box-shadow: .2em .2em 0 .05em var(--color-primary-light);
  border: none;
  padding: 1% 0;
  font-size: 1.2em;
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

:root {
  --color-primary: #ff9000;
  --color-primary-light: #f5d354;
  --color-secondary: #020171;
  --default-font-size: 12px;
}

body {
  background-color: var(--color-primary);
  width: 100vw;
  height: 100vh;
  font-family: Avenir Next, sans-serif;
  font-size: var(--default-font-size);
  margin: 0;
}

.hidden {
  display: none;
}

#game-ratio-container {
  margin: auto;
}

#game-container {
  width: 100%;
  padding-bottom: 70%;
  position: relative;
}

.game-state {
  height: 100%;
  padding: 3%;
  position: absolute;
  inset: 0;
}

button {
  cursor: pointer;
}

/*# sourceMappingURL=index.92014d09.css.map */
