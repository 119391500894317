@import "../font/font-avenir.css";
@import "./styles/state-playing.css";
@import "./styles/state-done.css";
@import "./styles/state-results.css";

/**
Notes:
The game must respect a certain aspect ratio
Which means that all size must adapt, but we can't trust neither vh nor vw
So the main layout screen are computed in % and we use javascript for font size
 */
/** Branding **/
* {
    box-sizing: border-box;
}

:root {
    --color-primary: #ff9000;
    --color-primary-light: #F5D354;
    --color-secondary: #020171;
    --default-font-size: 12px; /* This is updated using JS to match the game size */
}

body {
    margin: 0;
    background-color: var(--color-primary);
    width: 100vw;
    height: 100vh;
    font-family: 'Avenir Next', sans-serif;
    font-size: var(--default-font-size);
}

/** Utils **/
.hidden {
    display: none;
}

/** Game screens - Version with aspect ratio. Best implementation but support is not good enough yet **/
/*#game-container {*/
/*    margin: auto;*/
/*    width: 100%;*/
/*    max-width: 100vw;*/
/*    max-height: 100vh;*/
/*    aspect-ratio: 4 / 3;*/
/*}*/

/*.game-state {*/
/*    height: 100%;*/
/*    padding: 3%;*/
/*}*/
/** Game screens **/
#game-ratio-container {
    margin: auto;
}
#game-container {
    width: 100%;
    padding-bottom: 70%; /* Aspect ratio of 4/2.8*/
    position: relative;
}

.game-state {
    height: 100%;
    padding: 3%;
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
}

button {
    cursor: pointer;
}
