/** Playing screen **/
/* Layout */
#state-playing > *:not(:last-child) {
    margin-bottom: 1%;
}

#state-playing #game-title {
    height: 5%;
}
#state-playing #game-content {
    height: 58%;
}
#state-playing #game-answers {
    height: 34%;
}
#state-playing #game-answers #answers-details-container {
    height: 68%;
    margin-bottom: 2.5%;
}
#state-playing #game-answers #answers-footer {
    height: 23%;
}

/* Content */
#state-playing #game-title {
    text-align: center;
    color: white;
    font-size: 1em;
}

#state-playing #game-content {
    text-align: center;
}
#state-playing #game-content img {
    border: 0.4em solid white;
    border-radius: 0.4em;
    width: 57%;
    margin-bottom: 0.5%;
}

#state-playing #game-content #game-actions button {
    border: none;
    background-color: white;

    color: var(--color-secondary);
    text-transform: uppercase;
    font-weight: bold;
    padding: 1% 0;
    width: 25%;

    -webkit-box-shadow: .2em .2em 0 .05em var(--color-secondary);
    box-shadow: .2em .2em 0 .05em var(--color-secondary);
    font-size: 1em;
}
#state-playing #game-content #game-actions button:not(:last-child) {
    margin-right: 2%;
}
#state-playing #game-content #game-actions button.selected {
    background-color: var(--color-secondary);
    color: white;
    -webkit-box-shadow: .2em .2em 0 .05em var(--color-primary-light);
    box-shadow: .2em .2em 0 .05em var(--color-primary-light);
}

#state-playing #game-answers #answer {
    text-align: center;
    text-transform: uppercase;
    color: var(--color-secondary);
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 0.5%;
}

#state-playing #game-answers #answer-details {
    margin: 0 4%;
    background-color: white;
    padding: 1% 3%;
    font-size: 0.8em;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#state-playing #game-answers #answer-details p {
    margin: 0;
    color: var(--color-secondary);
}

#state-playing #game-answers #answers-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#state-playing #game-answers #answers-footer #answer-references {
    width: 95%;
    color: white;
    font-size: 0.5em;
}
#state-playing #game-answers #answers-footer #answer-references p {
    margin: 0;
}

#state-playing #game-answers #answers-footer button {
    width: 5%;
    border: none;
    margin: 0;
    padding: 0;
    background: transparent;
    line-height: normal;
    -webkit-appearance: none;
}
#state-playing #game-answers #answers-footer button img {
    width: 100%;
}
